<template>
    <div class="form-field-alert">
        <img :src="$assets.red.formError" alt="icon" />
        <p>
            {{ message }}
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'InputAlert',
    props: {
        message: String,
    },
})
</script>

<style lang="postcss" scoped>
.form-field-alert {
    @apply flex items-center space-x-1.5 text-sm text-red-500;
    img {
        @apply w-4;
    }
}
</style>
